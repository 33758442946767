<template>
  <DefaultDialog
    title="الصلاحيات"
    :openToEdit="openToEdit"
    editTitle="تعديل الصلاحيات"
    :openToView="openToView"
    viewTitle="عرض البيانات"
    @onCloseDialog="onCloseDialog"
    :toggleSize="true"
    :hideSubmitButtons="true"
  >
    <RolesManagementPermissionDetail
      slot-scope="{
        submitWithClose,
        submitWithNew,
        submitWithoutClose,
        closeDialog,
        isEdit,
        isView,
        isNew,
      }"
      :submitWithClose="submitWithClose"
      :submitWithNew="submitWithNew"
      :submitWithoutClose="submitWithoutClose"
      :setItemID="setItemID"
      :closeDialog="closeDialog"
      :isEdit="isEdit"
      :isView="isView"
      :isNew="isNew"
      :afterSubmit="afterSave"
      :itemId="itemId"
      :item="item"
    />
  </DefaultDialog>
</template>

<script>
import RolesManagementPermissionDetail from '../curd/RolesManagementPermissionDetail.vue';
import CRUDMixin from '@/mixins/DefaultDialogCRUD';
import {
  commitUserData,
  loadAndSaveCurrentUserInfo,
} from '@/libs/auth/authHelper';

export default {
  mixins: [CRUDMixin()],
  components: { RolesManagementPermissionDetail },
  methods: {
    async afterSave() {
      if (this.afterSubmit) this.afterSubmit();
      await loadAndSaveCurrentUserInfo();
      commitUserData();
    },
  },
};
</script>

<style lang="scss" scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DefaultDialog',{attrs:{"title":"الصلاحيات","openToEdit":_vm.openToEdit,"editTitle":"تعديل الصلاحيات","openToView":_vm.openToView,"viewTitle":"عرض البيانات","toggleSize":true,"hideSubmitButtons":true},on:{"onCloseDialog":_vm.onCloseDialog},scopedSlots:_vm._u([{key:"default",fn:function({
      submitWithClose,
      submitWithNew,
      submitWithoutClose,
      closeDialog,
      isEdit,
      isView,
      isNew,
    }){return _c('RolesManagementPermissionDetail',{attrs:{"submitWithClose":submitWithClose,"submitWithNew":submitWithNew,"submitWithoutClose":submitWithoutClose,"setItemID":_vm.setItemID,"closeDialog":closeDialog,"isEdit":isEdit,"isView":isView,"isNew":isNew,"afterSubmit":_vm.afterSave,"itemId":_vm.itemId,"item":_vm.item}})}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <form>
    <v-container fluid>
      <v-row>
        <v-col cols="12"> </v-col>
      </v-row>
    </v-container>

    <v-row>
      <v-col cols="12" v-if="itemId">
        <AuditLog
          :url="`app/audit-log/user-changes/${itemId}`"
          class="my-1 justify-end"
          :creationTime="creationTime"
          creationUsername="admin"
          :lastModificationTime="lastModificationTime"
          lastModificationUsername="admin"
          :colsName="{
            UserName: 'اسم المستخدم',
            Name: 'الاسم',
            Email: 'الايميل',
            PhoneNumber: 'رقم الهاتف',
            RoleNames: 'الدور',
          }"
        />
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-container fluid>
        <v-form ref="form" v-model="isValid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="name"
                label="الاسم"
                required
                outlined
                :counter="50"
                :error-messages="nameErrors"
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
              />
            </v-col>
            <v-col cols="12" v-show="false">
              <v-text-field
                v-model="surname"
                label="surname"
                required
                outlined
                :counter="50"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="phoneNumber"
                label="رقم الهاتف"
                style="direction: ltr"
                required
                outlined
                :counter="16"
                v-mask="'(####) ### ########'"
                :error-messages="phoneNumberErrors"
                @input="$v.phoneNumber.$touch()"
                @blur="$v.phoneNumber.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                label="الايميل"
                style="direction: ltr"
                required
                outlined
                :counter="50"
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="userName"
                label="اسم المستخدم"
                style="direction: ltr"
                required
                outlined
                :counter="50"
                :error-messages="userNameErrors"
                @input="$v.userName.$touch()"
                @blur="$v.userName.$touch()"
                autocomplete="username"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="password"
                label="كلمة المرور"
                style="direction: ltr"
                required
                outlined
                :counter="50"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :rules="passwordRules"
                autocomplete="new-password"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <MultiSelectRoles :loading="loadingRules" v-model="roleNames" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-checkbox
                v-model="isActive"
                color="primary"
                label="الحساب مفعل"
              />
            </v-col>
            <v-col cols="3" v-show="false">
              <v-checkbox
                v-model="lockoutEnabled"
                color="primary"
                label="lockout enabled"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  maxLength,
  minLength,
  email,
} from 'vuelidate/lib/validators';

import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';
import MultiSelectRoles from '../../../components/MultiSelectRoles.vue';
import AuditLog from '@/components/auditLog/AuditLog.vue';
import { getErrorDetails } from '@/helper/commonjs';

const initData = () => ({
  isValid: true,
  userName: '',
  name: '',
  surname: '',
  email: '',
  phoneNumber: '',
  isActive: true,
  lockoutEnabled: true,
  roleNames: [],
  password: '',
  showPassword: false,
  loadingRules: false,
  creationTime: '',
  lastModificationTime: '',
});

export default {
  props: [
    'submitWithClose',
    'submitWithNew',
    'submitWithoutClose',
    'setItemID',
    'afterSubmit',
    'closeDialog',
    'isNew',
    'isView',
    'isEdit',
    'itemId',
  ],
  mixins: [validationMixin],
  validations() {
    return {
      userName: { required, maxLength: maxLength(50), minLength: minLength(3) },
      name: { required, maxLength: maxLength(50) },
      email: { required, email },
      phoneNumber: { maxLength: maxLength(16) },
    };
  },
  data: () => ({ ...initData() }),
  async created() {
    if (this.isEdit || this.isView) {
      const responseData = await this.getById(this.itemId);
      if (responseData == null) return; // there is no data - error
      for (const k in this.$data) {
        const value = responseData[k];
        if (value == undefined) continue;
        this.$data[k] = value;
      }

      const userRoles = await this.getRolesById(this.itemId);
      this.roleNames = userRoles.map((x) => x.name);
    }
  },
  computed: {
    passwordRules() {
      if (!this.isNew && this.password.length == 0) return [];
      return [
        (v) => /(?=.*[a-z])/.test(v) || 'abc..z ' + 'ادخل حروف صغيرة',
        (v) => /(?=.*[A-Z])/.test(v) || 'ABC..Z ' + 'ادخل حروف كبيرة',
        (v) => /(?=.*[0-9])/.test(v) || '123..0 ' + 'ادخل ارقام',
        (v) =>
          /(?=.*[@#$%^*&+=])/.test(v) || '@#$%^&+=* ' + 'ادخل علامات مميزة',
        (v) =>
          /(?=.{6,})/.test(v) || 'يجب ان تكون كلمة المرور على الاقل 6 حروف',
      ];
    },
    userNameErrors() {
      const errors = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.maxLength && errors.push('لايمكن تجاوز الحد المسموح');
      !this.$v.userName.minLength &&
        errors.push('عدد الحروف على الاقل يجب ان تكون 3');
      !this.$v.userName.required && errors.push('اسم المستخدم مطلوب');
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength && errors.push('لايمكن تجاوز الحد المسموح');
      !this.$v.name.required && errors.push('الاسم مطلوب');
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.maxLength &&
        errors.push('لايمكن تجاوز الحد المسموح');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push('الايميل مطلوب');
      !this.$v.email.email && errors.push('الايميل غير صالح');
      return errors;
    },
  },
  methods: {
    doSave(postData) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .post('identity/users', { ...postData })
        .then((response) => {
          this.setItemID(response.data.id);
          this.creationTime = response.data.creationTime;
          this.lastModificationTime = response.data.lastModificationTime;
          if (response.status != 200) return false;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return { success: 1, response };
        })
        .catch((error) => {
          const details = getErrorDetails(error);
          return { msg: details.message };
        })
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    doUpdate(postData) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .put('identity/users/' + this.itemId, { ...postData })
        .then((response) => {
          if (response.status != 200) return false;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return { success: 1, response };
        })
        .catch((error) => {
          const details = getErrorDetails(error);
          return { msg: details.message };
        })
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    clearInput() {
      const _initData = initData();
      for (const k in this.$data) this.$data[k] = _initData[k];

      // reset validation
      this.resetValidation();
    },
    resetValidation() {
      this.$v.$reset();
      this.$refs.form.resetValidation();
    },
    isInvalid() {
      const isValidForm = this.$refs.form.validate();
      this.$v.$touch();
      const isInvalidValidators = this.$v.$invalid;
      if (!isValidForm || isInvalidValidators) return true;
      return false;
    },
    async getById(id) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .get('identity/users/' + id, { params: { language: 'all' } })
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
          this.closeDialog(false, true);
          return null;
        })
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    async getRolesById(id) {
      this.loadingRules = true;
      return this.$http
        .get('identity/users/' + id + '/roles', { params: { language: 'all' } })
        .then((response) => {
          const { data: { items = [] } = {} } = response;
          return items;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب الادوار');
          this.closeDialog(false, true);
          return [];
        })
        .finally(() => (this.loadingRules = false));
    },
    doSubmit(cb) {
      if (this.isInvalid()) {
        showSnackbarWarningMessage('يجب عدم ترك الاعمدة المطلوبة فارغة');
        return;
      }
      const userName = this.userName;
      const name = this.name;
      const surname = this.surname;
      const email = this.email;
      const phoneNumber = this.phoneNumber;
      const isActive = this.isActive;
      const lockoutEnabled = this.lockoutEnabled;
      const roleNames = this.roleNames;
      const password = this.password;
      const postData = {
        userName,
        name,
        surname,
        email,
        phoneNumber,
        isActive,
        lockoutEnabled,
        roleNames,
        password,
      };
      if (this.isNew) {
        this.doSave(postData).then((status) => {
          if (!status.success) {
            const { msg } = status;
            if (msg) showSnackbarFailMessage(msg);
            else showSnackbarFailMessage('حدث خطأ اثناء عملية الحفظ');
            return;
          }
          cb?.();
        });
      } else {
        // TODO : AH Need Refactor
        this.doUpdate(postData).then((status) => {
          if (!status.success) {
            const { msg } = status;
            if (msg) showSnackbarFailMessage(msg);
            else showSnackbarFailMessage('حدث خطأ اثناء عملية الحفظ');
            return;
          }
          cb?.();
        });
      }
    },
  },
  watch: {
    submitWithClose() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.closeDialog(true);
      });
    },
    submitWithNew() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.setItemID(null);
      });
    },
    submitWithoutClose() {
      this.doSubmit(() => {
        this.afterSubmit();
      });
    },
  },
  components: { MultiSelectRoles, AuditLog },
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-toolbar
            flat
            outlined
            style="position: sticky; top: 10px; z-index: 10"
          >
            <v-tabs v-model="tab">
              <v-tab
                v-for="item in groupsName"
                :key="item.name"
                class="justify-start"
                active-class="grey lighten-4"
              >
                <v-icon class="ml-4"> mdi-shield-key </v-icon>
                {{ item.displayName }}
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="tab" vertical class="ma-4">
            <v-tab-item v-for="group in groupsName" :key="group.name">
              <RolesGroupPermissionCard
                v-model="modifyPermissions"
                v-for="permission in getParentPermissions(group.name)"
                :key="permission.name"
                :permission="permission"
                :childs="getChildPermissions(group.name, permission.name)"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DefaultDialogCRUDDetails from '@/mixins/DefaultDialogCRUDDetails';
import RolesGroupPermissionCard from '../components/RolesGroupPermissionCard.vue';

const excludeGroup = [
  'SettingManagement',
  'AbpTenantManagement',
  'FeatureManagement',
];

const excludePermission = [
  'AlMurabaCMS.ContentView',
  'AlMurabaCMS.ContentUpload',
  'AlMurabaCMS.UploadLanguage',
  'AlMurabaCMS.Tag',
  'AlMurabaCMS.TagLanguage',
  'AlMurabaCMS.PageLanguage',
  // 'AlMurabaCMS.Navigation',
  'AlMurabaCMS.NavigationLanguage',
  'AlMurabaCMS.NavTypeLanguage',
  'AlMurabaCMS.ContentStatusLanguage',
  'AlMurabaCMS.ContentTypeLanguage',
  'AlMurabaCMS.CategoryLanguage',
  'AlMurabaCMS.DynamicContentLanguage',
  'AlMurabaCMS.ContentStatusLanguage',
  'AlMurabaCMS.ContentStatusLanguage',
  'AlMurabaCMS.ContentCategory',
  'AlMurabaCMS.DynamicPermission',
];

const initData = () => ({
  entityDisplayName: '',
  groups: [],
  modifyPermissions: [],
});

export default {
  data: () => ({
    ...initData(),
    tab: 0,
  }),
  mixins: [DefaultDialogCRUDDetails()],
  computed: {
    crudOptions() {
      const getQuery = `providerName=${this.item.name}&providerKey=${this.item.key}`;
      return {
        getUrl: 'permission-management/permissions?' + getQuery,
        postUrl: 'identity/roles',
        putUrl: 'permission-management/permissions?' + getQuery,
      };
    },
    groupsName() {
      if (!this.groups) return [];
      const groups = this.groups
        .filter((g) => !excludeGroup.includes(g.name))
        .map((g) => ({ displayName: g.displayName, name: g.name }));
      return groups;
    },
  },
  methods: {
    groupPermissions(groupName) {
      if (!this.groups) return [];
      const groupPermissions = this.groups.find((x) => x.name == groupName);
      const { permissions = [] } = groupPermissions || {};
      return permissions;
    },
    getParentPermissions(groupName) {
      const permissions = this.groupPermissions(groupName);

      const parentPermissions = permissions
        .filter((x) => {
          return (
            !excludePermission.includes(x.name) &&
            !excludePermission.includes(x.parentName)
          );
        })
        .filter((x) => x.parentName == null)
        .sort((x, y) => x.name.localeCompare(y.name));

      return parentPermissions;
    },
    getChildPermissions(groupName, parentPermissionName) {
      const permissions = this.groupPermissions(groupName);
      const childPermissions = permissions.filter(
        (x) =>
          x.parentName == parentPermissionName ||
          x.name == parentPermissionName,
      );
      return childPermissions;
    },
    isInvalid() {
      return false;
    },
    postedData() {
      const permissions = this.modifyPermissions.map((x) => ({
        name: x.name,
        isGranted: x.isGranted,
      }));
      const postData = { permissions };
      return postData;
    },
    clearInput() {
      this.clearData(initData());
    },
  },
  components: { RolesGroupPermissionCard },
};
</script>

<style lang="scss" scoped></style>
